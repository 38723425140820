$grass: #226565;
$buttonGrass: #1c7777;
$orange: #ef875c;
$white: #fff;
$blue: #113553;
$purple: #672482;
$lightOrange: #fff8f5;
$limeGreen: #3acb83;
$homePurple: #72288d;
$lightBlue: #f5fbff;
.background {
  background: $grass;
  color: $white;
  min-height: 100%;
  &.blue {
    background: $blue;
  }
  &.orange {
    background-color: $orange;
    &.with-image {
      background-image: url('../assets/orange-bg.png');
      background-size: 200%;
      background-repeat: no-repeat;
      background-position: top center;
    }
  }
  &.lightOrange {
    background-color: $lightOrange;
    color: $blue;
    &.with-image {
      background-image: url('../assets/light-orange-bg.png');
      background-size: 200%;
      background-repeat: no-repeat;
      background-position: top center;
    }
  }
  &.lightBlue {
    background-color: $lightBlue;
    color: $blue;
    &.with-image {
      background-image: url('../assets/flower-texture-blue.svg');
      background-size: 200%;
      background-repeat: no-repeat;
      background-position: top center;
    }
  }
}

.colour-orange {
  color: $orange;
}

.colour-blue {
  color: $blue;
}

.colour-purple {
  color: $purple;
}

.colour-white {
  color: $white;
}
