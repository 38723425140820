.qr-reader {
  max-height: -webkit-fill-available;
  overflow: hidden;
  section section:first-child {
    height: 100vh;
    div:first-child {
      box-shadow: none !important;
      border: 5px dashed rgba(255, 255, 255, 1) !important;
      width: 250px !important;
      height: 250px !important;
      margin: auto !important;
      left: 50% !important;
      margin-left: -125px !important;
      top: 50% !important;
      margin-top: -125px !important;
    }
  }
}

.no-camera {
  display: none;
  &.show {
    display: flex;
  }
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  div {
    width: 100%;
    text-align: center;
    margin-top: auto;
    padding: 20px;
    padding-bottom: 100px;
    background: rgba(255, 255, 255, 1);
    color: #000;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
  }
}
