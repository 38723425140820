.orientation {
  display: none;
  width: 100%;
  height: 100%;
  z-index: 999;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
}

@media only screen and (orientation: landscape) {
  .orientation {
    display: flex;
  }
}

.compatibility {
  display: none;
  width: 100%;
  height: 100%;
  z-index: 999;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
}

@media only screen and (orientation: portrait) and (min-width: 600px) {
  .compatibility {
    display: flex;
  }
}
