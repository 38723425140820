@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

@font-face {
  font-family: Krana;
  src: url('./fonts/Krana Fat B.otf');
}

html,
body {
  font-family: 'DM Sans', sans-serif;
}

.dm-sans {
  font-family: 'DM Sans', sans-serif;
}

.krana {
  font-family: 'Krana', sans-serif;
}

.font-20 {
  font-size: 20px;
}

.font-25 {
  font-size: 25px;
}

.font-30 {
  font-size: 30px;
}

.font-35 {
  font-size: 35px;
}

.font-40 {
  font-size: 40px;
}

.font-56 {
  font-size: 56px;
}

.lh-20 {
  line-height: 20px;
}

.lh-30 {
  line-height: 30px;
}

.lh-40 {
  line-height: 40px;
}

.font-18 {
  font-size: 18px;
  line-height: 18px;
}

.font-16 {
  font-size: 16px;
  line-height: 16px;
}

.bold {
  font-weight: 900;
}

.weight-none {
  font-weight: 300;
}

.text-align-left {
  text-align: left;
}

.text-transform-none {
  text-transform: none;
}

.text-transform-upper {
  text-transform: uppercase;
}

p {
  font-weight: 300;
}

.slanted {
  transform: rotate(-5deg);
}

@media screen and (max-width: 475px) {
  .font-40 {
    font-size: 35px;
  }
  .font-56 {
    font-size: 45px;
  }
}

@media screen and (max-width: 325px) {
  .font-25 {
    font-size: 20px;
  }
}
