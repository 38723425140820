.admin-logged-in {
  color: $white;
  h1,
  h2,
  h3 {
    font-family: 'Krana', sans-serif;
  }
  .objectList {
    img {
      max-width: 200px;
    }
  }

  .form {
    .formItem {
      background: rgba(255, 255, 255, 0.1);
      padding: 10px;
      margin: auto;
      margin-bottom: 10px;
      max-width: 700px;
      label {
        font-family: 'Krana', sans-serif;
        font-weight: bold;
      }
      img {
        max-width: 200px;
      }
    }
  }

  .objectBrowser {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    .ob-bg {
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.1);
    }
    .ob-content {
      width: 60%;
      left: 50%;
      margin-left: -30%;
      background: #fff;
      position: fixed;
      top: 10%;
      height: 80%;
      overflow-y: scroll;
    }
    &.hide {
      display: none;
    }
  }

  .objectList {
    margin-top: 30px;
    text-align: left;
    .ob-content--content {
      display: flex;
      flex-direction: column;
      max-width: 900px;
      margin: auto;
      .object-selectable {
        background: rgba(255, 255, 255, 0.1);
        margin-bottom: 10px;
        padding: 10px;
        .inside {
          display: flex;
          align-items: center;
          .column {
            &.object-asset {
              width: 110px;
            }
            &.object-name {
              flex-grow: 1;
              font-weight: bold;
              font-size: 30px;
              color: $white;
            }
            img {
              max-width: 100px;
              min-width: 100px;
            }
          }
        }
      }
    }
  }
}

.adminHeader {
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-family: 'Krana', sans-serif;
  a {
    display: block;
    padding: 10px 20px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.3s;
    background: transparent;
    &:hover,
    &.selected {
      background: rgba(0, 0, 0, 0.3);
    }
  }
}
