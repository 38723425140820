.loadAssets {
  position: fixed;
  width: 100%;
  height: 100%;
  transition: all 2s ease;
  z-index: 9998;
  &.animOut {
    transform: translateY(-300%);
    .bg-anim {
      transform: translateY(-200%);
    }
  }

  .bg-anim {
    position: fixed;
    left: 0;
    top: 0;
    background: url('../../assets/bg-anim.svg');
    width: 100%;
    height: 280%;
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: -110%;
    transition: all 3s ease;
  }
  .inside {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 9999;
    .content {
      width: 100%;
      text-align: center;
      font-size: 18px;
    }
    .anim-logo {
      background-image: url('../../assets/transition-logo.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      width: 50%;
      padding-top: 50%;
      background-position: center center;
      z-index: 996;
      margin-left: auto;
      margin-right: auto;
    }

    .loadingBar {
      width: 80%;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
      height: 3px;
      background: #fff;
      border-radius: 3px;
      .loadingBar--inside {
        background: #072438;
        height: 100%;
        border-radius: 3px;
        transition: all 0.3s;
      }
    }
  }
}
