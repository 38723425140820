@keyframes fadeInUp {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.fadeInUp {
  transform: translateY(10px);
  opacity: 0;
  animation: fadeInUp 0.3s ease;
  animation-fill-mode: forwards;
}

@keyframes fadeInUpRotate10 {
  0% {
    transform: translateY(10px) rotate(0);
    opacity: 0;
  }
  100% {
    transform: translateY(0px) rotate(-10deg);
    opacity: 1;
  }
}

.fadeInUpRotate10 {
  transform: translateY(10px);
  opacity: 0;
  animation: fadeInUpRotate10 0.3s ease;
  animation-fill-mode: forwards;
}

@keyframes fadeInUpRotate5 {
  0% {
    transform: translateY(10px) rotate(0);
    opacity: 0;
  }
  100% {
    transform: translateY(0px) rotate(-5deg);
    opacity: 1;
  }
}

.fadeInUpRotate5 {
  transform: translateY(10px);
  opacity: 0;
  animation: fadeInUpRotate5 0.3s ease;
  animation-fill-mode: forwards;
}

@keyframes fadeInUpScale {
  0% {
    transform: translateY(10px) scale(0.9);
    opacity: 0;
  }
  100% {
    transform: translateY(0px) scale(1);
    opacity: 1;
  }
}

.fadeInUpScale {
  transform: translateY(10px);
  opacity: 0;
  animation: fadeInUpScale 0.3s ease;
  animation-fill-mode: forwards;
}

.rotateSlow {
  animation: rotateSlow 60s linear infinite;
}

.delay-1 {
  animation-delay: 0.2s;
}

.delay-2 {
  animation-delay: 0.4s;
}

.delay-3 {
  animation-delay: 0.6s;
}

.delay-4 {
  animation-delay: 0.8s;
}

.delay-5 {
  animation-delay: 1s;
}

.delay-6 {
  animation-delay: 1.2s;
}

.delay-7 {
  animation-delay: 1.4s;
}

.delay-8 {
  animation-delay: 1.6s;
}

.delay-9 {
  animation-delay: 1.8s;
}

.delay-10 {
  animation-delay: 2s;
}

.delay-20 {
  animation-delay: 4s;
}
