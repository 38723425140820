.popup.guardianFound {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: 99;
  width: 100%;
  visibility: visible;
  opacity: 1;
  transition: all 0.5s ease;
  &.hide {
    visibility: hidden;
    opacity: 0;
  }
  .inside {
    background: #113553;
    padding: 0px;
    border-radius: 20px;
    width: 90%;
    color: #fff;
    padding-top: 30px;
    position: relative;
    .orange {
      color: #ef875c;
    }
    .close-btn {
      position: absolute;
      border-radius: 50px;
      top: 0;
      right: 0;
      margin-right: -15px;
      margin-top: -25px;
      width: 75px;
      height: 75px;
      box-shadow: none;
      background: #fff8f5;
      background-image: url('../../assets/close.svg');
      background-size: 30%;
      background-position: center center;
      background-repeat: no-repeat;
      border: 0;
    }
    .anim-burst-lines {
      position: relative;
      padding-top: 100%;
      margin-top: -80px;
      &:before {
        background-size: 90%;
      }
    }
    .heading {
      transform: rotate(-5deg);
      text-transform: uppercase;
      padding-bottom: 20px;
      padding: 20px;
    }
    .text {
      padding: 20px;
      padding-top: 0;
      font-size: 18px;
      &.last {
        padding-bottom: 0;
      }
    }
    .char-pp {
      position: absolute;
      margin-top: -65%;
      left: 50%;
      margin-left: -75px;
      img {
        width: 150px;
      }
    }
  }
}
