.initialPage .itemsSelector .tradeable.view {
  padding-top: 0;
  opacity: 0;
}

.initialPageContainer {
  .slide {
    display: none;
    &.show {
      display: block;
    }
  }

  .carousel {
    .slide {
      display: block;
      color: #113553;
    }
  }

  .first-slide {
    padding: 0;
  }

  .onboard-logo {
    width: 70%;
    padding-top: 70%;
    background: url('../../../assets/onboardLogo.svg');
    background-size: 100%;
    background-position: center center;
    margin: auto;
    position: relative;
    z-index: 2;

    position: absolute;
    width: 40%;
    padding-top: 40%;
    margin-left: -20%;
    left: 50%;
    top: 30%;
  }
  .onboard-house {
    width: 100%;
    padding-top: 60%;
    background: url('../../../assets/onboard-house.svg');
    background-size: 110%;
    background-position: center center;
    background-repeat: no-repeat;
    margin: auto;
    margin-top: -80px;
    position: relative;
    z-index: 2;
  }

  .slide1 {
    &.page-onboard {
      .onboard-logo {
        width: 60%;
        padding-top: 60%;
        margin-left: -30%;
        top: 23%;
      }
      .onboard-house {
        width: 100%;
        padding-top: 145%;
        background: url('../../../assets/onboard-house.svg');
        background-position: 50% 70%;
        background-position: center center;
        background-repeat: no-repeat;
        margin: auto;
        position: relative;
        z-index: 2;
      }
      &.onboardSlide {
        .onboard-house {
          padding-top: 60%;
        }
      }
    }
  }

  .onboard-cropped {
    text-align: center;
    padding-top: 50px;
    img {
      width: 80%;
    }
  }

  .onboard-area {
    width: 100%;
    background-size: 100%;
    background-position: center center;
    &.area-garden {
      background-image: url('../../../assets/a-board-gardens.svg');
    }
    img {
      width: 100%;
    }
  }

  .pick-name {
    margin-top: 20%;
  }

  .name-generator {
    position: relative;
    width: 80%;
    margin: auto;
    .input-text {
      background: #084b4b;
      width: 100%;
      padding: 20px;
      border-radius: 10px;
      margin: auto;
      margin-top: 10px;
    }
    button.button-clear {
      position: absolute;
      right: 0;
      margin-top: -20px;
    }
  }
  .onboard-items {
    margin-top: 20px;
    .bag-dig {
      background: url('../../../assets/collectable-bag-small.svg');
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      display: block;
      width: 80px;
      height: 80px;
      margin: auto;
    }
  }
}

.onboardSlide {
  .onboard-logo {
    background-image: url('../../../assets/hide-n-find-logo-white.svg');
    width: 45%;
    padding-top: 45%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
  }
  .onboard-house {
    margin-top: 0;
  }
  .onboard-content {
    padding-bottom: 100px;
  }
}
