.p {
  margin-top: 20px;
  margin-bottom: 20px;
}
.page-menu.page {
  padding-top: 9 0px;
  background-image: url('../../assets/flower-texture@2x.png');
  background-size: 180%;
  background-position: center 100px;
  background-repeat: no-repeat;
  .scanner-btn {
    position: relative;
    color: #fff;
    display: block;
    text-decoration: none;
    .found-text {
      position: absolute;
      background: #084b4b;
      z-index: 3;
      padding: 10px 20px;
      font-size: 16px;
      color: #fff;
      border-radius: 25px;
      min-width: 180px;
      transform: rotate(-10deg);
      margin-top: -20px;
    }
    .flowers-overlay {
      background: url('../../assets/home-dig-here-large.svg');
      background-size: 35%;
      position: absolute;
      bottom: 0;
      left: -3%;
      width: 106%;
      padding-top: 65%;
      background-repeat: no-repeat;
      margin-bottom: -20px;
      background-position: bottom left;
    }
  }
  .customise-btn {
    display: block;

    margin: auto;
    max-width: 80%;
    margin-top: 30px;
    text-decoration: none;
    position: relative;
    &.zones-btn {
      margin-top: 20px;
      .btn-inside {
        background: #72288d;
      }
    }
    .btn-inside {
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      z-index: 2;
      font-family: 'Krana', sans-serif;
      padding: 20px;
      padding-top: 28px;
      background: #113553;
      border-radius: 35px;
      position: relative;
    }
    .customise-hat {
      position: absolute;
      right: 0;
      width: 97px;
      height: 73px;
      background-image: url('../../assets/home-customise-hat.svg');
      background-size: 100%;
      margin-top: -50px;
      margin-right: -30px;
    }
    .customise-moustache {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 61px;
      height: 47px;
      background-image: url('../../assets/home-customise-moustache.svg');
      background-size: 100%;
      margin-bottom: -10px;
      margin-right: 0px;
      z-index: 4;
    }
    .zones-map-img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 61px;
      height: 51px;
      background-image: url('../../assets/home-map.svg');
      background-size: 100%;
      margin-bottom: -10px;
      margin-right: 0px;
      z-index: 4;
      transform: rotate(10deg);
    }
  }

  .button.parents {
    max-width: 80%;
    margin: auto;
    margin-top: 20px;
    background: #fff !important;
    padding-left: 40px;
    .flex {
      .grow {
        padding-right: 10px;
      }
    }
  }
}

.page-menu-container {
  .content-area {
    box-shadow: 0px 0px 30px 40px rgba(255, 255, 255, 1);
    text-align: center;
  }
}
