@keyframes tradeAnimation1 {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes tradeAnimation2 {
  0% {
    transform: translateY(-10px);
  }
  25% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  75% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-10px);
  }
}

.itemFound.newItem {
  left: 0;
  width: 100%;

  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .starContainer {
    left: 0;
    width: 100%;
    height: 100vh;
    top: 0;
    position: absolute;
    overflow: hidden;
    .star {
      background: url('../../assets/star-yellow.svg');
      background-position: center center;
      background-size: 100%;
      background-repeat: no-repeat;
      width: 150%;
      margin-left: -25%;
      height: 100%;
    }
  }
  img {
    width: 65%;
  }
  .subHeading {
    max-width: 60%;
    margin: auto;
    margin-top: 20px;
  }
  .heading {
    transform: rotate(-5deg);

    text-align: center;
    font-weight: 900;
    text-transform: uppercase;

    span {
      display: block;
    }
  }
}
.tradeChoosePage {
  .heading {
    width: 80%;
    margin: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.itemsSelector {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
  .tradeable {
    width: 33.3%;
    padding-top: 33.3%;
    position: relative;
    opacity: 0.5;
    transition: all 0.3s;
    &.view {
      width: 33.3%;
      padding-top: 33.3%;
      &.tradeable-container {
        img {
          opacity: 1;
        }
      }
    }
    &.full,
    &.selected {
      opacity: 1;
    }
    button {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: transparent;
      border: 0;
      img {
        width: 100%;
      }
    }
    &.tradeable-container {
      background: transparent;
      border: 0;
      -webkit-appearance: none;
      position: absolute;
      left: 0;
      top: 0;
      margin-top: 10%;
      margin-left: 10%;
      width: 80%;
      img {
        width: 100%;
        transition: all 0.3s;
        opacity: 0.2;
      }
    }
    &:after {
      display: block;
      content: '';
      background: url('../../assets/tick.svg');
      width: 60px;
      height: 60px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      right: 20px;
      top: 10px;
      visibility: hidden;
      opacity: 0;
      transform: translateY(5px);
      transition: all 0.3s;
    }
    &.selected {
      &:after {
        visibility: visible;
        opacity: 1;
        transform: translateY(0px);
      }
      button {
        img {
          opacity: 1;
        }
      }
    }
  }
}
.tradesList-scroller {
  height: 45%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 90;
}
.tradesList-container {
  display: flex;
  position: relative;
  height: 100%;
  .trade-container {
    padding-left: 0px;
    animation: tradeAnimation1 10s linear infinite;
    width: 200px;
    &:first-child {
      .trade {
      }
    }
    &.item-1 {
      margin-top: 30px;
    }
    &.item-2 {
      margin-top: 150px;
      animation: tradeAnimation2 15s linear infinite;
    }

    &.item-3 {
      margin-top: 0px;
      animation: tradeAnimation2 15s linear infinite;
    }

    &.item-4 {
      margin-top: 150px;
    }
  }
  .trade {
    background: #244d6f;
    border-radius: 20px;
    padding: 15px;
    max-width: 260px;
    min-width: 260px;
    // margin-left: -30px;
    .leftBy {
      padding-left: 10px;
      font-size: 16px;
      text-align: left;
      span {
        display: block;
      }
      .colour-orange {
        font-size: 18px;
        font-weight: bold;
      }
      .colour-opaque {
        opacity: 0.5;
      }
    }
    .player {
      display: flex;
      .avatar {
        max-width: 50px;
        img {
          width: 100%;
        }
      }
    }
    .item {
      position: absolute;
      width: 70px;
      left: 50%;
      margin-left: -35px;
      img {
        width: 100%;
      }
    }
  }
}

.scroll-indicator {
  position: absolute;
  bottom: 5%;
  left: 50%;
  width: 130px;
  height: 130px;
  background: url('../../assets/swipe-graphic.svg');
  background-size: 100%;
  background-position: center center;
  z-index: 100;
  min-width: 130px;
  margin-left: -65px;
  transition: all 0.5s;
  top: 45%;
  visibility: visible;
  opacity: 1;
}

.scroll-indicator.scroll-started {
  opacity: 0;
  visibility: hidden;
}

.blue-fader {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 50%;
  background: rgb(17, 53, 83);
  background: linear-gradient(
    0deg,
    rgba(17, 53, 83, 1) 63%,
    rgba(17, 53, 83, 0) 100%
  );
  z-index: 99;
}
