@import './fonts';
@import './colours';

//components
@import './components/button';
@import './components/bubble';

//animations
@import './animations/rotateSlow';
@import './animations/animations';

//admin
@import './admin/admin';

html {
  overflow: auto;
}

html,
body {
  overflow-x: hidden;
  background: $grass;
  font-size: 24px;
  &.blue {
    background: $blue;
  }
}

div#main,
#app,
#root,
body,
html {
  height: 100%;
  scroll-behavior: smooth;
}

.full {
  width: 100%;
  height: 100%;
}

.page {
  padding: 20px;
  padding-top: 93px;
  position: relative;
  &.no-logo {
    padding-top: 20px;
  }
  &.no-padding {
    padding-left: 0;
    padding-right: 0;
  }
}

body .carousel.carousel-slider .control-arrow:hover {
  background: transparent;
}
body .carousel.carousel-slider .control-arrow,
body .carousel.carousel-slider .control-arrow:active,
body .carousel.carousel-slider .control-arrow:focus {
  background: transparent !important;
}

.anim-burst-lines {
  position: absolute;
  margin-left: 0%;
  left: 0;
  width: 100%;
  padding-top: 120%;
  overflow: hidden;
  &:before {
    top: 0;
    position: absolute;
    content: '';
    display: block;
    width: 120%;
    padding-top: 120%;
    left: -10%;
    background-image: url('../assets/burst-lines.png');
    background-repeat: no-repeat;
    background-position: center center;
    animation: rotateSlow 60s linear infinite;
    background-size: 100%;
  }
  &.onboard-lines {
    width: 120%;
    margin-left: -10%;
    padding-top: 140%;
    margin-top: -40%;
  }
}

.logo {
  position: absolute;
  &.sticky {
    position: fixed;
  }
  top: 20px;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 12;
}
.margin-auto {
  margin: auto;
}
.flex {
  display: flex;
  .grow {
    flex-grow: 1;
  }
  &.justify-center {
    justify-content: center;
  }
  &.align-center {
    align-items: center;
  }
}

.content-area {
  text-align: left;
  font-size: 18px;
  padding: 20px;
  &.white {
    background: #fff;
    color: $blue;
  }
}

body .padding- {
  &20 {
    padding: 20px;
  }
  &top {
    padding-top: 20px;
    &-10 {
      padding-top: 10px;
    }
  }
  &bottom {
    padding-bottom: 20px;
    &-10 {
      padding-bottom: 10px;
    }
  }
}

body .z-15 {
  z-index: 15 !important;
}

body {
  .lightBlue {
    .whitePageInside {
      h2 {
        color: $purple;
      }
    }
    .carousel.carousel-slider .control-arrow.control-next:before {
      border-left-color: $purple;
    }
    .carousel.carousel-slider .control-arrow.control-prev:before {
      border-right-color: $purple;
    }
    .carousel ul.control-dots li.dot {
      background: $purple;
    }
  }
}

.whitePageInside {
  padding: 10px;
  border-radius: 20px;
  background: #fff;
  margin-top: 50px;
  text-align: left;
  &.no-margin {
    margin-top: 0;
  }
  h2 {
    color: $orange;
    margin-top: 0;
    padding: 10px;
    margin-bottom: 0;
    font-size: 30px;
    text-align: center;
  }
}

.img404 {
  padding-top: 0px;
}

.page404 {
  color: $white;
}

.padding-none {
  padding: 0;
}

.margin-none {
  margin: 0;
}
body .page-zones .carousel {
  .slide {
    img {
      width: 100%;
    }
  }
}
body .carousel {
  .slide {
    img {
      width: 80%;
    }
  }
}

body .carousel ul.control-dots {
  bottom: 0;
  margin: 0;
  li.dot {
    background: $orange;
    box-shadow: none;
    width: 18px;
    height: 18px;
  }
}
body .carousel.carousel-slider {
  padding-bottom: 40px;
  .slide {
    font-size: 18px;
    .text {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
body .carousel.carousel-slider .control-arrow {
  color: $orange;
  opacity: 1;
  &:before {
    border-right: 18px solid $orange;
    border-top: 18px solid transparent;
    border-bottom: 18px solid transparent;
  }
  &.control-next {
    &:before {
      border-right: 18px solid transparent;
      border-left: 18px solid $orange;
      border-top: 18px solid transparent;
      border-bottom: 18px solid transparent;
      margin-right: -18px;
    }
  }
}

.z-100 {
  z-index: 100;
}

.z-101 {
  z-index: 101 !important;
}

body,
html {
  &.body-background- {
    &orange {
      background-color: $orange;
    }
    &lightOrange {
      background-color: $lightOrange;
    }
    &blue {
      background-color: $blue;
    }
  }
}

div#main {
  max-width: 600px;
  margin: auto;
}

button.none.transparent.link,
a {
  background: none;
  border: none;
  color: #72288d;
  text-decoration: underline;
}

.ww-logo {
  width: 100%;
  margin: auto;
  margin-top: 50px;
  .weight-none {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    a {
      width: 100%;
      padding: 10px;
    }
  }
}

#react-media-library-modal {
  .modal-header {
    &:after {
      content: 'To ensure loading times stay low, please ensure unused images are removed from the media library';
      display: block;
      font-weight: bold;
      background: #fb6161;
      padding: 5px;
      margin-bottom: 5px;
    }
  }
}
