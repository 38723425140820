@keyframes WhoWantsToSpinForever {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes WhoWantsToBounceForever {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}

.character {
  position: absolute;
  &.sticky {
    position: fixed;
  }
  bottom: 0;
  left: 10%;
  width: 80%;
  height: 50%;
  text-align: center;
  opacity: 1;
  visibility: visible;
  transform: translateY(0px) scale(1);
  transition: all 0.5s;
  &.size-small {
    transform: translateY(0px) scale(0.8);
  }
  &.smaller {
    transform: translateY(0px) scale(0.8) translateX(150px);
  }
  &.hide {
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px) scale(0.8);
    &.size-small {
      transform: translateY(10px) scale(0.6);
    }
  }
  .burst-lines {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: center center;
    z-index: 0;
    background-image: url('../../assets/burst-lines.png');
    background-size: 100%;
    background-repeat: no-repeat;
    animation: WhoWantsToSpinForever 120s linear 0s infinite;
    opacity: 0.5;
  }
  img {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 50%;
    margin-left: 25%;
    animation: WhoWantsToBounceForever 10s linear 0s infinite;
    margin-top: 30px;
  }
}
