.admin-logged-in button,
.admin-logged-in .button {
  background: $orange;
  color: $white;
  text-decoration: none;
  font-weight: bolder;
  border-radius: 45px;
  display: inline-block;
  text-transform: uppercase;
  font-family: 'Krana', sans-serif;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  border: 0;
  line-height: 40px;
  padding: 5px 15px;
  padding-top: 8px;
  font-size: 20px;
  transition: all 0.3s;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2),
      inset 0px 0px 3px 10px rgba(255, 255, 255, 0.2);
  }
  &.purple {
    background: $purple;
  }
}

.button {
  background: $orange;
  color: $white;
  text-decoration: none;
  font-weight: bolder;
  padding: 10px 20px;
  border-radius: 45px;
  display: inline-block;
  text-transform: uppercase;
  font-family: 'Krana', sans-serif;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  font-size: 35px;
  border: 0;
  padding-top: 18px;
  line-height: 40px;
  &.smallest {
    padding: 5px 15px;
    padding-top: 8px;
    font-size: 20px;
  }
  &.right {
    margin-left: auto;
  }
  &button {
    background: black;
  }
  &.font-25 {
    font-size: 25px;
  }
  &.disabled {
    color: #244d6f;
    background: #092841;
  }
  &.dm-sans {
    font-family: 'DM Sans', sans-serif;
    padding: 18px;
    text-transform: none;
  }
  &.lightGreen {
    background: $grass;
  }
  &.purple {
    background: $purple;
  }
  &.green {
    background: $buttonGrass;
  }
  &.darkGreen {
    background: $buttonGrass;
  }
  &.white {
    background: #fff8f5;
    color: $purple;
  }
  &.limeGreen {
    background: $limeGreen;
    color: $white;
  }
  &.parents {
    background: #fff !important;
  }
  &.small-radius {
    border-radius: 20px;
  }
  &.start-scanner {
    width: 80%;
    margin: auto;
    padding: 40px;
    padding-top: 48px;
  }
  &.no-text {
    padding-top: 10px;
  }
  span.sendIcon {
    margin-left: auto;
    background: url('../../assets/send-message.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    display: block;
    width: 40px;
    height: 40px;
    margin-left: 30px;
  }
  span.back {
    background: url('../../assets/back-arrow.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    display: block;
    width: 30px;
    height: 25px;
  }
  span.zoom {
    background: url('../../assets/enlarge-icon.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    display: block;
    width: 40px;
    height: 35px;
  }
  span.close {
    background: url('../../assets/close-white.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    display: block;
    width: 24px;
    height: 24px;
  }
  span.info {
    background: url('../../assets/info.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    display: block;
    width: 40px;
    height: 40px;
  }

  span.info-white {
    background: url('../../assets/info-white.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    display: block;
    width: 40px;
    height: 40px;
  }

  &.padding-normal {
    padding: 10px;
  }
  &.padding-20 {
    padding: 20px;
  }
}

.button-container {
  width: 100%;
  text-align: center;
  padding-bottom: 20px;

  &.left,
  &.right {
    .button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &.left {
    display: flex;
    position: fixed;
    left: 0px;
    padding-left: 20px;
    padding-right: 20px;
    top: 20px;
    z-index: 11;
    text-align: left;
  }
  &.right {
    display: flex;
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 11;
    text-align: right;
  }
  &.bottom {
    position: fixed;
    z-index: 999;
    bottom: 0;
    left: 0;
  }
  &.static {
    position: absolute !important;
  }

  &.no-fixed {
    position: relative;
    padding-top: 20px;
  }
}

.button-clear {
  background: transparent;
  margin: 0;
  padding: 0;
  border: 0;
  span.btn-refresh {
    background: url('../../assets/btn-refresh.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    display: block;
    width: 80px;
    height: 80px;
  }
}

@media screen and (max-width: 475px) {
  .button {
    font-size: 30px;
    &.font-25 {
      font-size: 25px;
    }
  }
}

@media screen and (max-width: 325px) {
  .button {
    font-size: 25px;
    &.font-25 {
      font-size: 25px;
    }
  }
}
