.zones-image {
  width: 100%;
  padding-top: 110%;
  background-size: cover;
  background-position: center center;
}

.zones-list {
  display: flex;
  justify-content: center;
  align-items: center;
  .zone {
    flex-grow: 1;
  }
}

.zones-bottom {
  max-width: 80%;
  margin: auto;
  margin-top: 30px;
}

.page-zones {
  .zone--header {
    margin-top: 30px;
  }
  .zone--title {
    margin-top: 10px;
    font-size: 28px;
  }
  .zone--image {
    margin-top: 0px;
  }
}

.zones-carousel {
  width: 100%;
}
