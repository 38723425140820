.modal-backdrop {
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  &.show {
    opacity: 1;
    visibility: visible;
  }
  z-index: 10;
}

.modal {
  z-index: 11;
  opacity: 0;
  visibility: hidden;
  background: transparent;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  transition: all 0.6s;
  transform: translateY(10px);

  display: flex !important;
  justify-content: center;
  align-items: center;

  &.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }

  .modal-dialog {
    width: 90%;
    height: 90%;
    padding: 20px;
    background: #fff;
    position: relative;
  }

  .close {
    position: absolute;
    right: 0;
    top: 0;
  }

  .modal-title {
    font-weight: bold;
    font-size: 2em;
  }
}
#react-media-library-modal {
  .nav.nav-tabs {
    display: none;
  }
}

#react-media-library-tabs-tabpane-upload {
  padding: 30px;
  background: rgba(0, 0, 0, 0.1);
  text-align: center;
  border: 2px dashed rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}

#react-media-library-tabs-tabpane-library {
  .row {
    display: flex;
    &:first-child {
      max-height: 500px;
      overflow: scroll;
      position: relative !important;
    }
    .d-flex {
      display: flex;
      ul {
        display: flex;
        li {
          padding-left: 10px;
          padding-right: 10px;
          list-style: none;
        }
      }
    }
    .col-md-4 {
      width: 25%;
    }
  }
}

#react-media-library-tabs-tabpane-library {
  .row {
    display: flex;
    flex-wrap: wrap;

    .card {
      border: 5px solid transparent;
      background: rgba(0, 0, 0, 0.1);
      padding: 10px;
      border: 5px solid transparent;
      &.bg-primary {
        background: rgba(0, 0, 0, 0.2);
        border: 5px solid rgba(0, 0, 0, 0.3);
      }
      img {
        height: auto;
        width: 100%;
      }
      .list-group-item {
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        &:first-child {
          font-weight: bold;
        }
      }
    }
  }
}
