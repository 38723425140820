.page-avatar {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  transition: all 0.3s;
  background-position: top center;
  background-size: 200%;
  background-repeat: no-repeat;
  background-color: #90c89d;
  overflow: scroll;
  &.show {
    visibility: visible;
    opacity: 1;
    background-size: 160%;
    .avatar {
      transform: scale(1);
    }
  }

  .avatar {
    text-align: center;
    margin-top: 30px;
    transform: scale(0.8);
    transition: all 0.8s;
    img {
      width: 70%;
    }
  }

  .avatar-content {
    border-radius: 20px;
    width: 80%;
    margin: auto;
    background: #fff;
    padding: 20px;
    font-size: 18px;
    .title {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      span {
        display: block;
        font-size: 22px;
      }
    }
    .content {
      margin-top: 10px;
    }
  }
}
