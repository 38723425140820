.page-customise {
  .player--avatar {
    z-index: 3;
    position: absolute;
    left: 5%;
    width: 30%;
    margin-top: -8%;
    img {
      width: 100%;
      z-index: 1;
    }
  }
  .player-info {
    position: fixed;
    background: #ef875c;
    width: 100%;
    left: 0;
    top: 0;
    padding-top: 140px;
    padding-bottom: 10px;
    border-bottom: 3px solid rgba(0, 0, 0, 0.1);
    z-index: 9;
  }
  .player--name {
    position: relative;
    width: 70% !important;
    height: 100px;
    margin-top: -20px;
    left: 30%;
    margin-left: -5% !important;
    .openAvatar {
      position: absolute;
      z-index: 13;
      right: 0;
      margin-top: -30px;
      height: 45px;
      margin-right: 10px;
    }
    .title {
      background-image: url('../../assets/title-bg.svg');
      background-size: 100%;
      width: 150px;
      height: 45px;
      position: absolute;
      z-index: 3;
      background-repeat: no-repeat;
      background-position: center center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 900;
      font-size: 18px;
      color: #532815;
      margin-left: 25px;
      margin-top: -20px;
      font-family: 'Krana', sans-serif;
    }
    button {
      padding: 10px 10px;
    }
    .inside {
      position: absolute;
      left: 0;
      top: 0;
      background: #532815;
      border-radius: 20px;
      padding: 30px 20px;
      font-family: 'Krana', sans-serif;
      font-weight: 900;
      width: 100%;
      z-index: 2;
      display: flex;
      justify-content: left;
      align-items: center;
      height: 100%;
      padding-left: 20% !important;
    }
    width: 90%;
    margin-left: 5%;
  }
  .page-customise--inside {
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 38%;
    background: #fff;
    z-index: 1;
    border-radius: 20px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .zone--title {
    font-weight: bold;
    padding: 10px;
    border-bottom: 1px solid #113553;
    width: 80%;
    margin-left: 10%;
    font-size: 18px;
  }

  .zone {
    .zone--markers {
      padding-top: 10px;
      width: 90%;
      margin-left: 5%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .zone--markers-marker {
      width: 33.3%;
      position: relative;
      button {
        padding: 10px;
        background: transparent;
        border: 0;
        width: 100%;
        img {
          width: 100%;
        }
      }
      &.current {
        &:after {
          display: block;
          content: '';
          background: url('../../assets/tick.svg');
          width: 60px;
          height: 60px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          position: absolute;
          right: -10px;
          top: -10px;
        }
      }
    }
  }

  .collectables {
    padding-top: 10px;
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .tradeable {
    width: 33.3%;
    padding: 0px;
    img {
      width: 100%;
    }
  }
}
