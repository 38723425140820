@keyframes bgAnim {
  0% {
    transform: translateY(100%);
  }
  10% {
    transform: translateY(100%);
  }
  30% {
    transform: translateY(-30%);
  }
  70% {
    transform: translateY(-30%);
  }
  100% {
    transform: translateY(-100%);
  }
}
@keyframes ball1 {
  0% {
    transform: translateY(800px);
  }
  40% {
    transform: translateY(-400px);
  }
  100% {
    transform: translateY(-400px);
  }
}
@keyframes ball2 {
  0% {
    transform: translateY(800px);
  }
  60% {
    transform: translateY(-600px);
  }
  100% {
    transform: translateY(-600px);
  }
}
@keyframes logoAnim {
  0% {
    transform: translateY(900px) scale(0.8);
  }
  10% {
    transform: translateY(900px) scale(0.8);
  }
  50% {
    transform: translateY(0) scale(1);
  }
  70% {
    transform: translateY(0) scale(1);
  }
  100% {
    transform: translateY(-900px) scale(1);
  }
}
.foundTransition {
  z-index: 9995;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  &.hidden {
    display: none;
  }
  .inside {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .bg-anim {
      background: url('../../assets/bg-anim.svg');
      width: 100%;
      height: 280%;
      background-size: 100%;
      animation: bgAnim 5s ease 0s 1;
      animation-fill-mode: forwards;
      background-repeat: no-repeat;
    }
    .anim-logo {
      background-image: url('../../assets/transition-logo.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      width: 50%;
      padding-top: 50%;
      background-position: center center;
      position: absolute;
      z-index: 9996;
      animation: logoAnim ease 0s 1;
      animation-fill-mode: forwards;
      animation-duration: 5s !important;
      left: 50%;
      margin-left: -25%;
      top: 50%;
      margin-top: -25%;
    }
    .ball {
      z-index: 9997;
      border-radius: 50%;
      position: absolute;
      width: 120px;
      height: 120px;
      animation-duration: 5s !important;
      &.ball-1 {
        background: #e0a707;
        left: 20%;
        top: 20%;
        animation: ball1 ease 0s 1;
        animation-fill-mode: forwards;
      }
      &.ball-2 {
        background: #e0a707;
        left: 60%;
        top: 30%;
        animation: ball2 ease 0s 1;
        animation-fill-mode: forwards;
      }
    }
  }
}
