.popup--allfound {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  .inside {
    width: 90%;
    padding: 20px;
    border-radius: 20px;
  }
  img {
    width: 100%;
  }
  button {
    margin-top: -30px;
  }
}
