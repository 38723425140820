@keyframes fadeMeInDarling {
  0% {
    opacity: 0;
    transform: translateY(5px);
  }
  75% {
    opacity: 0;
    transform: translateY(5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes fadeMeInUser {
  0% {
    opacity: 0;
    transform: translateY(5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.speech-container {
  &.guardian {
    position: fixed;
    min-width: 350px;
    bottom: 0;
    height: 40%;
    .speech {
      &:after {
        display: none;
      }
    }
  }
}
.speech {
  margin-top: 20px;
  padding-bottom: 20px;
  animation: fadeMeInDarling 1.5s;
  animation-delay: 0s;
  position: relative;
  z-index: 99;
  .inside {
    background: $white;
    color: $blue;
    text-align: left;
    font-weight: bold;
    padding: 20px;
    width: 70%;
    border-radius: 20px;
    font-size: 18px;
    img {
      max-width: 100px;
      margin: auto;
      display: block;
      margin-top: 10px;
    }
  }
  &:after {
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;

    border-top: 20px solid $white;
    left: 50%;
    position: absolute;
  }
  // &.n-2 {
  //   .inside {
  //     margin-left: 30%;
  //   }
  // }
  // &.n-3 {
  //   .inside {
  //     margin-left: 10%;
  //   }
  // }
  &.noArrow {
    padding-bottom: 0px;
    &:after {
      display: none !important;
    }
  }
  &.user {
    animation: fadeMeInUser 1s;
    .inside {
      margin-left: auto !important;
      background: $limeGreen;
    }
    &:after {
      border-top: 20px solid $limeGreen;
    }
  }
}

@keyframes typingAnim {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-8px);
  }
  75% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0px);
  }
}

.typing {
  background: #092841;
  display: flex;
  width: 150px;
  padding: 20px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  margin: auto;
  .typingBubble {
    background: $white;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border-radius: 50%;
    &:last-child {
      margin-right: 0;
    }
    animation: typingAnim 1.6s ease 0s infinite;
    &.typingAnim2 {
      animation: typingAnim 1.6s ease 0.2s infinite;
    }
    &.typingAnim3 {
      animation: typingAnim 1.6s ease 0.4s infinite;
    }
  }
}
